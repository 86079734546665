<template>
  <div class="body">
    <!-- <div class="flex-row">
      <div v-for="(item, index) in buttonList" :key="index" :class="[index === isSelected ? 'buttonOne' : 'buttonTwo']"
        @click="alartClick(index)">{{ item.alarmTypeName }}</div>
    </div> -->
    <div class="alart-head">
      <alart-head-left />
      <alart-head-center />
      <alart-head-right />
    </div>
    <alart-bottom />

  </div>
</template>

<script>
import alartHeadCenter from './components/alartHeadCenter.vue';
import AlartHeadLeft from './components/alartHeadLeft.vue';
import AlartHeadRight from './components/alartHeadRight.vue';
import AlartBottom from './components/alartBottom.vue'
export default {
  components: {
    AlartHeadLeft,
    alartHeadCenter,
    AlartHeadRight,
    AlartBottom
  },
  mounted() {
    this.getTypeList()
  },
  data() {
    return {
      buttonList: [],
      formInline: {},
      isSelected: 0
    };
  },
  methods: {
    alartClick(index) {
      this.isSelected = index
    },
    onSubmit() { },
    getTypeList() {
      this.$http.post('alarmType/list', {}).then(res => {
        if (res.data.code === 200) {
          this.buttonList = res.data.data.data
          console.log("告警头部", res.data.data.data);
        }
      })
    }
    // /api/deviceAlarmRecord/list
  }
}
</script>

<style lang="scss" scoped>
.body {
  background: #223f6c;
  height: 100%;
  padding: 10px;
  overflow-y: auto;
  .flex-row {
    display: flex;
    margin-bottom: 10px;

    .buttonOne {
      width: 110px;
      height: 40px;
      border-radius: 5px;
      border: 1px solid #02a7f0;
      background-color: #02a7f0;
      box-sizing: border-box;
      font-family: "Arial Bold", "Arial", sans-serif;
      font-weight: 700;
      color: #ffffff;
      text-align: center;
      line-height: 40px;
      margin-right: 10px;
      cursor: pointer;
    }

    .buttonTwo {
      width: 100px;
      height: 40px;
      border-radius: 5px;
      border: 1px solid rgba(151, 179, 203, 0.57);
      background-color: rgba(2, 167, 240, 0);
      box-sizing: border-box;
      font-family: "Arial", sans-serif;
      color: #97b3cb;
      text-align: center;
      margin-right: 10px;
      line-height: 40px;
      cursor: pointer;
    }
  }

  .alart-head {
    height: calc(30vh - 8px);
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
  }
}

/deep/ .el-checkbox__inner {
  background-color: rgba(255, 255, 255, 0);
}

/deep/ .el-pagination {
  display: flex;
  justify-content: flex-end;
}

/deep/ .el-pagination button:disabled {
  background: rgba(255, 255, 255, 0);
}

/deep/ .el-pagination .btn-next {
  background: #132c45;
}

/deep/ .el-pager li.active {
  color: #f2f2f2;
  background-color: #00b2e6;

  cursor: default;
}

/deep/ .el-pager li {
  color: #f2f2f2;
  background-color: #132c45;
  ;

  cursor: default;
}

/deep/ .el-pagination__total {
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 12px;
}

/deep/ .el-pagination__jump {
  color: #fff;
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  font-size: 15px;
}

::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
  background-color: #223f6c !important;
}
</style>
